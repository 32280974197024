import { TemplateType } from 'types/templates.type';
import User from 'components/data/User';
import Setup from 'components/data/Setup';
import Layer from '../types/layer.type';
import { AllProperties } from '../types/layerProperties.type';
import { DEFAULT_AUDIO_FILE_TYPES, DEFAULT_IMAGE_FILE_TYPES, DEFAULT_VIDEO_FILE_TYPES } from '../constants';
import Template, { DesignerSettings, TemplateData } from '../types/template.type';
import { getTemplateData } from './data.helpers';

/**
 * Class for configuration helpers.
 */
class ConfigHelpers {
    /**
     * Check if the template is an Adobe template.
     * @param templateType - The template type to check if it is an Adobe template. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template is an Adobe template.
     */
    static isAdobeTemplate = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType);
    };

    /**
     * Check if the shortcuts dialog should be shown.
     * @param templateType - The template type to check if the shortcuts dialog should be shown. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the shortcuts dialog should be shown.
     */
    static hasShortcutsDialog = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return !this.isAdobeTemplate(templateType);
    };

    /**
     * Check if the version management should be shown.
     * @param templateType - The template type to check if version management should be shown. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the version management should be shown.
     */
    static hasVersionManagement = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return !this.isAdobeTemplate(templateType);
    };

    /**
     * Check if Lottie is available.
     * @param templateType - The template type to check if Lottie is available. If not provided, it will be fetched from the template data.
     * @returns - Returns true if Lottie is available.
     */
    static hasLottie = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['displayAdDesigned', 'dynamicVideoDesigned'].includes(templateType);
    };

    /**
     * Check if the template has hover.
     * @param templateType - The template type to check if the template has hover. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template has hover.
     */
    static hasHover = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['displayAdDesigned'].includes(templateType);
    };

    /**
     * Check if the template has animations.
     * @param templateType - The template type to check if the template has animations. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template has animations.
     */
    static hasAnimations = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['displayAdDesigned', 'dynamicVideoDesigned'].includes(templateType);
    };

    /**
     * Check if the template has media layers.
     * Video and audio layers.
     * @param templateType - The template type to check if the template has media layers. If not provided, it will be fetched from the template data.
     * @returns - Returns true if the template has media layers.
     */
    static hasMediaLayers = (templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return ['dynamicVideoDesigned'].includes(templateType);
    };

    /**
     * Checks if video layers can be added.
     * @param templateType - The template type to check if video layers can be added. If not provided, it will be fetched from the template data.
     * @param enableAnimations - If animations are enabled. If not provided, it will be fetched from the designer settings.
     * @param enableVideo - If video is enabled. If not provided, it will be fetched from the designer settings.
     * @returns If video layers can be added.
     */
    static hasVideoLayers = (
        templateType?: TemplateData['type'],
        enableAnimations?: DesignerSettings['enableAnimations'],
        enableVideo?: DesignerSettings['enableVideo']
    ): boolean => {
        if (enableAnimations === undefined) enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations');

        if (!enableAnimations) {
            return false;
        }

        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        const hasMediaLayers = ConfigHelpers.hasMediaLayers(templateType);

        if (hasMediaLayers) {
            return true;
        }

        if (templateType === 'displayAdDesigned') {
            if (enableVideo === undefined) enableVideo = getTemplateData<DesignerSettings['enableVideo']>('designerSettings.enableVideo');
            const isSuperAdmin = User.get('type') === 'superadmin';
            return enableVideo && isSuperAdmin;
        }

        return false;
    };

    /**
     * Checks if audio layers can be added.
     * @param templateType - The template type to check if audio layers can be added. If not provided, it will be fetched from the template data.
     * @param enableAnimations - If animations are enabled. If not provided, it will be fetched from the designer settings.
     * @param enableAudio - If audio is enabled. If not provided, it will be fetched from the designer settings.
     * @returns If audio layers can be added.
     */
    static hasAudioLayers = (
        templateType?: TemplateData['type'],
        enableAnimations?: DesignerSettings['enableAnimations'],
        enableAudio?: DesignerSettings['enableAudio']
    ): boolean => {
        if (enableAnimations === undefined) enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations');

        if (!enableAnimations) {
            return false;
        }

        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        const hasMediaLayers = ConfigHelpers.hasMediaLayers(templateType);

        if (hasMediaLayers) {
            return true;
        }

        if (templateType === 'displayAdDesigned') {
            if (enableAudio === undefined) enableAudio = getTemplateData<DesignerSettings['enableAudio']>('designerSettings.enableAudio');
            const isSuperAdmin = User.get('type') === 'superadmin';
            return enableAudio && isSuperAdmin;
        }

        return false;
    };

    /**
     * Checks if Lottie layers can be added.
     * @returns If Lottie layers can be added.
     */
    static hasLottieLayers = (): boolean => {
        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        if (!ConfigHelpers.hasLottie(templateType)) return false;
        const enableLottie = getTemplateData<DesignerSettings['enableLottie']>('designerSettings.enableLottie');
        return enableLottie;
    };

    /**
     * Check if the text should not shrink.
     * @param width - Width of the layer.
     * @param height - Height of the layer.
     * @returns - Returns true if the text should not shrink.
     */
    static isShrinkTextDisabled = (width: AllProperties['width'], height: AllProperties['height']): boolean => {
        return (
            typeof width.value === 'string' ||
            typeof height.value === 'string' ||
            width.value === null ||
            height.value === null ||
            width.unit === '%' ||
            height.unit === '%' ||
            width.value === 0 ||
            height.value === 0
        );
    };

    /**
     * Checks if safezone should be shown for the current template type
     * @param type The template type
     * @returns True if safezone should be shown
     */
    static shouldShowSafezone = (templateType?: TemplateType): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return templateType === 'dynamicImageDesigned' || templateType === 'dynamicVideoDesigned';
    };

    /**
     * Get the file types for image files.
     * @param layerType - The layer type to get the file types for.
     * @param templateType - The template type to check which file types are compatible.
     */
    static getFileTypesForUpload = (layerType?: Layer['type']): string | string[] => {
        if (Setup.hasModule('mediaManagement') && User.hasRight(['assetManagerMediaWrite', 'assetManagerMediaRead', 'assetManagerMediaManagement'])) {
            switch (layerType) {
                case 'image':
                case 'AE_image': {
                    const templateType = getTemplateData<TemplateData['type']>('templateData.type');

                    if (templateType === 'displayAdDesigned') {
                        return [...DEFAULT_IMAGE_FILE_TYPES, 'gif'];
                    }

                    // ? Only support Webp for non display templates as some services gives an error.
                    return [...DEFAULT_IMAGE_FILE_TYPES, 'webp'];
                }
                case 'video':
                case 'AE_video': {
                    const templateType = getTemplateData<TemplateData['type']>('templateData.type');

                    // Only support webm for non display templates.
                    if (templateType === 'displayAdDesigned') {
                        return [...DEFAULT_VIDEO_FILE_TYPES].filter((fileType) => fileType !== 'webm');
                    }

                    return [...DEFAULT_VIDEO_FILE_TYPES];
                }
                case 'audio':
                case 'AE_audio':
                    return DEFAULT_AUDIO_FILE_TYPES;
                case undefined:
                default:
                    return [...DEFAULT_IMAGE_FILE_TYPES];
            }
        }

        if (layerType === undefined) return ['image'];

        if (layerType === 'image') {
            const templateType = getTemplateData<TemplateData['type']>('templateData.type');
            if (['displayAdDesigned'].includes(templateType)) return [layerType, '.gif'];
            return [layerType];
        }

        if (layerType === 'video') return 'video';
        if (layerType === 'audio') return DEFAULT_AUDIO_FILE_TYPES;
        return ['image'];
    };
}

export { ConfigHelpers };
