import set from 'lodash/set';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import Translation from 'components/data/Translation';
import Setup from 'components/data/Setup';
import User from 'components/data/User';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { StringHelpers } from 'helpers/string.helpers';
import TemplateDesignerStore from '../data/template-designer-store';
import {
    AlertInput,
    DynamicLayerInput,
    AllSettings,
    BaseInput,
    ButtonGroupSettings,
    ColorSettings,
    CustomInput,
    DividerInput,
    DropdownInput,
    FeedItemSelectorInput,
    FieldInput,
    GroupInput
} from '../types/dynamicLayer.type';
import Template, { CurrentView, DesignerSettings, TemplateData, View } from '../types/template.type';
import cloneDeep from '../utils/cloneDeep';
import { generateKey } from '../utils/generateKey';
import { getTemplateData } from './data.helpers';
import { Attributes } from '../types/attribute.type';
import Layer from '../types/layer.type';
import { attributeInputs } from '../components/dynamic-layers/components/dynamic-layer-edit/config/attribute-inputs';
import LayerHelpers from './layer.helpers';
import { AI_MODULE, ANIMATION_LAYER_TYPES, APRIMO_ACCOUNT_IDS, DYNAMIC_LAYER_CONDITIONS_ACCOUNT_IDS, MAX_INPUT_DEPTH, UNSPLASH_MODULE } from '../constants';
import { InterfaceSetupHelpers } from './interface-setup.helpers';
import { ColorHelpers } from './color.helpers';
import { feedSelectorInput } from '../components/dynamic-layers/components/dynamic-layer-edit/components/edit-forms/config/feed-selector-input';
import { Selected, formatData } from '../components/dynamic-layer-input-dialog';
import { divider } from '../components/dynamic-layers/components/dynamic-layer-edit/components/edit-forms/config/divider';
import { alert } from '../components/dynamic-layers/components/dynamic-layer-edit/components/edit-forms/config/alert';
import { group } from '../components/dynamic-layers/components/dynamic-layer-edit/components/edit-forms/config/group';
import { dropdown } from '../components/dynamic-layers/components/dynamic-layer-edit/components/edit-forms/config/dropdown';
import { FeatureHelpers } from './features.helpers';
import { Features } from '../types/features.type';
import { customInput } from '../components/dynamic-layers/components/dynamic-layer-edit/components/edit-forms/config/custom-input';
import { FeedMappingHelpers } from './feed-mapping.helpers';

class DynamicLayerHelpers {
    /**
     * Get the dynamic layer inputs for the given frame type.
     * If enableAnimations is false, the animation layers will be filtered out.
     * @param frameType - The frame type to get the dynamic layers for.
     * @param dynamicLayers - The dynamic layers to get the inputs from.
     * @returns The dynamic layer inputs for the given frame type.
     */
    static getAllInputs = (
        dynamicLayers?: Template['dynamicLayers'],
        frameType?: View['frameType'],
        options?: {
            enableAnimations?: DesignerSettings['enableAnimations'];
            enableLottie?: DesignerSettings['enableLottie'];
            enableVideo?: DesignerSettings['enableVideo'];
            enableAudio?: DesignerSettings['enableAudio'];
        }
    ): DynamicLayerInput[] => {
        if (dynamicLayers === undefined) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const {
            enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations'),
            enableLottie = getTemplateData<DesignerSettings['enableLottie']>('designerSettings.enableLottie'),
            enableVideo = getTemplateData<DesignerSettings['enableVideo']>('designerSettings.enableVideo'),
            enableAudio = getTemplateData<DesignerSettings['enableAudio']>('designerSettings.enableAudio')
        } = options || {};

        if (enableAnimations && enableLottie && enableVideo && enableAudio) return dynamicLayers[frameType];

        const filterInputs = (items: DynamicLayerInput[]) => {
            return items.reduce((acc, item) => {
                const newItem = item;

                if ('children' in newItem && newItem.children.length) {
                    newItem.children = filterInputs(newItem.children);
                    acc.push(newItem);
                    return acc;
                }

                if ('layerKey' in newItem && newItem.layerKey !== 'formatData' && newItem.layerKey !== 'format' && newItem.layerKey !== 'jobOptions') {
                    const layer = LayerHelpers.findLayer(newItem.layerKey);

                    if (!layer) {
                        return acc;
                    }

                    if (!enableAnimations && ANIMATION_LAYER_TYPES.includes(layer.type)) {
                        return acc;
                    }

                    if (!enableLottie && layer.type === 'lottie') {
                        return acc;
                    }

                    if (!enableVideo && layer.type === 'video') {
                        return acc;
                    }

                    if (!enableAudio && layer.type === 'audio') {
                        return acc;
                    }
                }

                acc.push(newItem);
                return acc;
            }, [] as DynamicLayerInput[]);
        };

        return filterInputs(cloneDeep(dynamicLayers[frameType]));
    };

    /**
     * Find an input by key in the dynamic layers.
     * @param inputKey - The key of the input to find.
     * @param frameType - The frame type to search for the input in.
     * @param dynamicLayers - The dynamic layers to search in.
     * @returns The input with the given key.
     */
    static findInput = (
        inputKey: DynamicLayerInput['key'],
        frameType?: View['frameType'] | undefined,
        dynamicLayers?: Template['dynamicLayers'] | undefined
    ): DynamicLayerInput | null => {
        if (dynamicLayers === undefined) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const findInputByKey = (dynamicLayers: DynamicLayerInput[]) => {
            for (const dynamicLayer of dynamicLayers) {
                if (dynamicLayer.key === inputKey) {
                    return dynamicLayer;
                }

                if ('children' in dynamicLayer && dynamicLayer.children.length > 0) {
                    const foundLayer = findInputByKey(dynamicLayer.children);
                    if (foundLayer) return foundLayer;
                }
            }

            return null;
        };

        return findInputByKey(dynamicLayers[frameType]);
    };

    /**
     * Find an input by layer key and attribute.
     * @param layerKey - The key of the layer to find the input for.
     * @param attribute - The attribute of the input to find.
     * @param frameType - The frame type to search for the input in.
     * @param dynamicLayers- The dynamic layers to search in.
     * @returns The input with the given layer key and attribute.
     */
    static findInputByLayerKeyAttribute = (
        layerKey: Layer['key'],
        attribute: Attributes,
        frameType?: View['frameType'] | undefined,
        dynamicLayers?: Template['dynamicLayers'] | undefined
    ): DynamicLayerInput | null => {
        if (dynamicLayers === undefined) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const findLayerKeyAttribute = (dynamicLayers: DynamicLayerInput[]) => {
            for (const dynamicLayer of dynamicLayers) {
                if ('layerKey' in dynamicLayer && dynamicLayer.layerKey === layerKey && dynamicLayer.attribute === attribute) {
                    return dynamicLayer;
                } else if (!('layerKey' in dynamicLayer) && attribute === dynamicLayer.attribute) {
                    return dynamicLayer;
                }

                if ('children' in dynamicLayer && dynamicLayer.children.length > 0) {
                    const foundLayer = findLayerKeyAttribute(dynamicLayer.children);
                    if (foundLayer) return foundLayer;
                }
            }

            return null;
        };

        return findLayerKeyAttribute(dynamicLayers[frameType]);
    };

    /**
     * Find the feed selector input.
     * @returns The feed selector input or null if not found.
     */
    static findFeedSelectorInput = (frameType?: View['frameType']): FeedItemSelectorInput | null => {
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentInputs = dynamicLayers[frameType];

        let foundFeedSelector: FeedItemSelectorInput | null = null;

        /**
         * Find the feed selector input in the inputs.
         * @param currentInputs - The inputs to search for the feed selector input in.
         */
        function findInput(currentInputs: DynamicLayerInput[]) {
            for (const currentInput of currentInputs) {
                if (currentInput.type === 'feedSelectorInput') {
                    foundFeedSelector = currentInput as FeedItemSelectorInput;
                    break;
                } else if ('children' in currentInput && currentInput.children.length > 0) {
                    findInput(currentInput.children);
                }
            }
        }

        findInput(currentInputs);

        return foundFeedSelector;
    };

    /**
     * Set the given input to read only.
     * @param inputKey - The key of the input.
     * @param readOnly - The read only value to set.
     */
    static setInputReadOnly = (inputKey: DynamicLayerInput['key'], readOnly: boolean): void => {
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentDynamicLayers = dynamicLayers[frameType] || [];

        function setInputReadOnly(inputs: DynamicLayerInput[]) {
            inputs.forEach((input) => {
                if (input.key === inputKey) {
                    input.settings.readOnly = readOnly;
                } else if ('children' in input) {
                    setInputReadOnly(input.children);
                }
            });
        }

        setInputReadOnly(currentDynamicLayers);

        TemplateDesignerStore.save([`dynamicLayers.${frameType}`, currentDynamicLayers]);
    };

    /**
     * Get the linked inputs for the given frame type.
     * If no frame type is given, the current frame type will be used.
     * @param frameType - The frame type to get the linked inputs for.
     * @returns The linked inputs for the given frame type.
     */
    static getLinkedInputs = (frameType?: View['frameType']): DynamicLayerInput[] => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const currentFeedMapping = feedMapping[frameType];

        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const currentInputs = dynamicLayers[frameType];

        const linkedInputs: DynamicLayerInput[] = [];

        const findLinkedInput = (inputs: DynamicLayerInput[]) => {
            for (const input of inputs) {
                if ('layerKey' in input) {
                    const isLinked = FeedMappingHelpers.isLinkedToInput(currentFeedMapping, input.layerKey, input.attribute);

                    if (isLinked) {
                        linkedInputs.push(input);
                    }
                }

                if ('children' in input && input.children.length > 0) {
                    findLinkedInput(input.children);
                }
            }
        };

        findLinkedInput(currentInputs);

        return linkedInputs;
    };

    /**
     * Add an input to the dynamic layers.
     * If there is an active input, the new input will be added as children to the active input.
     * @param input - The input to add.
     */
    static addInput = (inputs: DynamicLayerInput[], activeInput?: DynamicLayerInput | null): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const currentInputs = dynamicLayers[frameType];

        if (activeInput && currentInputs.length > 0) {
            const addInputsToChildren = (currentInputs: DynamicLayerInput[], levelDeep: number) => {
                for (const input of currentInputs) {
                    if (input.key === activeInput.key) {
                        if ((activeInput.type === 'group' || activeInput.attribute === 'visibility') && 'children' in input) {
                            if (levelDeep < MAX_INPUT_DEPTH) {
                                input.children = [...input.children, ...inputs];
                            } else {
                                currentInputs.push(...inputs);
                            }
                        } else {
                            const activeItemIndex = currentInputs.indexOf(input);
                            currentInputs.splice(activeItemIndex + 1, 0, ...inputs);
                        }
                        return;
                    } else if ('children' in input && input.children.length > 0) {
                        addInputsToChildren(input.children, levelDeep + 1);
                    }
                }
            };

            addInputsToChildren(currentInputs, 0);
        } else {
            currentInputs.push(...inputs);
        }

        TemplateDesignerStore.save([`dynamicLayers.${frameType}`, currentInputs]);
    };

    /**
     * Add an input field to the dynamic layers.
     * @param inputs - The inputs to add.
     * @param activeInput - The active input to add the new input to.
     * @returns The added input fields.
     */
    static addInputField = (selected: Selected, activeInput?: DynamicLayerInput | null): DynamicLayerInput[] => {
        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const dynamicLayerInputs = Object.keys(selected).reduce((inputs, layerKey) => {
            const layer = (layerKey === 'formatData' ? formatData : LayerHelpers.findLayer(layerKey)) as Layer | null;
            if (!layer) return inputs;

            const attributes = selected[layerKey];

            attributes.forEach((attribute) => {
                const data = attributeInputs[layer.type].all[attribute] || attributeInputs[layer.type]?.superAdmin?.[attribute];

                const defaultSettings = this.getInputSettings(data.settings[data.defaultSetting], layer, attribute);

                const input: FieldInput = {
                    key: generateKey('field'),
                    type: 'field',
                    label: this.generateFieldLabel(layer.title, attribute),
                    attribute,
                    layerKey: layer.key,
                    children: [],
                    settings: cloneDeep(defaultSettings)
                };

                const isLinked = FeedMappingHelpers.isLinkedToInput(feedMapping[frameType], layer.key, attribute);

                /**
                 * Check if there is a feed item selector.
                 * Then make the new input editable based on the usage in the feed item selector settings.
                 */
                const readOnly = (() => {
                    if (!isLinked) return false;
                    const feedSelector = this.findFeedSelectorInput();
                    if (!feedSelector) return false;
                    return feedSelector.settings.usage !== 'custom';
                })();

                input.settings.readOnly = readOnly;

                // Overwrite the model if it is set in the attribute input.
                if (data.overwriteModel) {
                    input.overwriteModel = data.overwriteModel;
                }

                inputs.push(input);
            });

            return inputs;
        }, [] as DynamicLayerInput[]);

        if (dynamicLayerInputs.length === 0) return [];

        this.addInput(dynamicLayerInputs, activeInput);

        const message =
            dynamicLayerInputs.length === 1
                ? Translation.get('general.messages.dynamicLayerInputAdded_one', 'template-designer', { number: dynamicLayerInputs.length })
                : Translation.get('general.messages.dynamicLayerInputAdded_other', 'template-designer', { number: dynamicLayerInputs.length });

        SnackbarUtils.success(message);

        TemplateDesignerStore.save(
            [
                ['view.currentView', CurrentView.DynamicLayers],
                ['state.openItemTree', true]
            ],
            { saveToHistory: false }
        );

        return dynamicLayerInputs;
    };

    /**
     * Add a group input.
     * @param activeInput - The active input to add the group to.
     * @returns The group input.
     */
    static addGroup = (activeInput: DynamicLayerInput | null): GroupInput => {
        const groupInput: GroupInput = {
            key: generateKey('group'),
            type: 'group',
            label: group.label,
            attribute: group.attribute,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            settings: cloneDeep(group.settings.subSection!),
            children: []
        };

        this.addInput([groupInput], activeInput);

        return groupInput;
    };

    /**
     * Add a divider input.
     * @param activeInput - The active input to add the divider to.
     * @returns The divider input.
     */
    static addDivider = (activeInput: DynamicLayerInput | null): DividerInput => {
        const dividerInput: DividerInput = {
            key: generateKey('divider'),
            type: 'divider',
            label: divider.label,
            attribute: divider.attribute,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            settings: cloneDeep(divider.settings.divider!)
        };

        this.addInput([dividerInput], activeInput);

        return dividerInput;
    };

    /**
     * Add an alert input.
     * @param activeInput - The active input to add the alert to.
     * @returns The alert input.
     */
    static addAlert = (activeInput: DynamicLayerInput | null): AlertInput => {
        const alertInput: AlertInput = {
            key: generateKey('alert'),
            type: 'alert',
            label: alert.label,
            attribute: alert.attribute,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            settings: cloneDeep(alert.settings.alert!)
        };

        this.addInput([alertInput], activeInput);

        return alertInput;
    };

    /**
     * Add an drppdown input.
     * @param activeInput - The active input to add the dropdown to.
     * @returns The dropdown input.
     */
    static addDropdown = (activeInput: DynamicLayerInput | null): DropdownInput => {
        const dropdownInput: DropdownInput = {
            key: generateKey('dropdown'),
            type: 'dropdown',
            label: dropdown.label,
            helperText: '',
            tooltip: '',
            attribute: dropdown.attribute,
            settings: cloneDeep(dropdown.settings[dropdown.defaultSetting])
        };

        this.addInput([dropdownInput], activeInput);

        return dropdownInput;
    };

    /**
     * Add an drppdown input.
     * @param activeInput - The active input to add the dropdown to.
     * @returns The dropdown input.
     */
    static addCustomInput = (activeInput: DynamicLayerInput | null): CustomInput => {
        const newCustomInput: CustomInput = {
            key: generateKey('customInput'),
            type: 'customInput',
            label: customInput.label,
            attribute: customInput.attribute,
            settings: cloneDeep(customInput.settings[customInput.defaultSetting])
        };

        this.addInput([newCustomInput], activeInput);

        return newCustomInput;
    };

    /**
     * Add a feed selector input.
     * @param activeInput - The active input to add the feed selector to.
     * @returns The feed selector input.
     */
    static addFeedSelectorInput = (activeInput: DynamicLayerInput | null): FeedItemSelectorInput => {
        const feedItemSelector: FeedItemSelectorInput = {
            key: generateKey('feedSelectorInput'),
            type: 'feedSelectorInput',
            label: feedSelectorInput.label,
            attribute: feedSelectorInput.attribute,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            settings: cloneDeep(feedSelectorInput.settings.feedSelectorInput!)
        };

        this.addInput([feedItemSelector], activeInput);

        return feedItemSelector;
    };

    /**
     * Get the settings for the input.
     * @param config - The config from the input.
     * @param layer - The layer that is used in the settings.
     * @param attribute - The attribute that is used in the settings.
     * @returns The settings for the input.
     */
    static getInputSettings = (config: unknown, layer: Layer | null, attribute: DynamicLayerInput['attribute']): DynamicLayerInput['settings'] => {
        if (typeof config === 'function' && layer) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (config as (layer: Layer, attribute: Attributes) => unknown)(layer, attribute);
        }

        return config as DynamicLayerInput['settings'];
    };

    /**
     * Checks if dynamicLayers has an input for the layer and the corresponding attribute e.g. a color input for layer headline.
     * @param layerKey - The layer to check.
     * @param attribute - The attribute to check e.g. color, backgroundColor or shadow.
     * @param dynamicLayers - The dynamic layers to check in.
     * @returns True if dynamic layers has an input for the layer with the correct attribute.
     */
    static checkDynamicLayersForAttribute = (layerKey: Layer['key'], attribute: string, dynamicLayers?: DynamicLayerInput[]): boolean => {
        if (dynamicLayers === undefined) {
            const frameType = getTemplateData<View['frameType']>('view.frameType');
            dynamicLayers = getTemplateData<DynamicLayerInput[]>(`dynamicLayers.${frameType}`);
        }

        for (const input of dynamicLayers) {
            if ('layerKey' in input && input.layerKey === layerKey && input.attribute === attribute) {
                return true;
            } else if ('children' in input) {
                if (this.checkDynamicLayersForAttribute(layerKey, attribute, input.children)) {
                    return true;
                }
            }
        }

        return false;
    };

    /**
     * Check if an input exists for the given attribute and layer key.
     * @param attribute - The attribute of the input to check.
     * @param layerKey - The key of the layer to check if the input exists for.
     * @returns True if the input exists, false otherwise.
     */
    static dynamicLayerInputExists = (attribute: Attributes, layerKey?: Layer['key']): boolean => {
        if (!layerKey) return false;

        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers', { clone: false });
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentInputs = dynamicLayers[frameType];

        const checkInputExists = (inputs: DynamicLayerInput[]): boolean => {
            for (const input of inputs) {
                if ('layerKey' in input && input.layerKey === layerKey && input.attribute === attribute) {
                    return true;
                }

                if ('children' in input && input.children.length > 0) {
                    const inputExists = checkInputExists(input.children);
                    if (inputExists) return inputExists;
                }
            }

            return false;
        };

        return checkInputExists(currentInputs);
    };

    /**
     * Delete input by key and attribute.
     * @param inputKey - The key of the input to remove.
     */
    static deleteInput = (inputKey: DynamicLayerInput['key']): void => {
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentInputs = dynamicLayers[frameType];

        /**
         * Remove the input from dynamic layers.
         * @param inputs - The inputs to remove the input from.
         * @returns The inputs without the removed input.
         */
        const removeInput = (currentInputs: DynamicLayerInput[]): void => {
            for (const currentInput of currentInputs) {
                if (currentInput.key === inputKey) {
                    const currentInputIndex = currentInputs.indexOf(currentInput);
                    if (currentInputIndex !== -1) {
                        currentInputs.splice(currentInputIndex, 1);
                    }

                    break;
                }

                if ('children' in currentInput && currentInput.children.length > 0) {
                    removeInput(currentInput.children);
                }
            }
        };

        removeInput(currentInputs);
        TemplateDesignerStore.save([`dynamicLayers.${frameType}`, currentInputs]);
    };

    /**
     * Update the input.
     * @param model - The model to update.
     * @param value - The value of the setting.
     */
    static updateInput = (input: DynamicLayerInput, model: string, value: unknown): void => {
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');

        /**
         * Update the setting.
         * @param currentInputs - The current inputs to update.
         */
        const updateSetting = (currentInputs: DynamicLayerInput[]) => {
            currentInputs.forEach((currentInput) => {
                if (currentInput.key === input.key) {
                    set(currentInput, model, value);
                } else if ('children' in currentInput && currentInput.children.length > 0) {
                    updateSetting(currentInput.children);
                }
            });
        };

        updateSetting(dynamicLayers[frameType]);
        TemplateDesignerStore.save([`dynamicLayers.${frameType}`, dynamicLayers[frameType]]);
    };

    /**
     * Check if an input can move up or down.
     * @param inputKey - The key of the input to check.
     * @param direction - The direction to check if the input can move.
     * @param frameType - The frame type to check if the input can move.
     * @param dynamicLayers - The dynamic layers to check if the input can move.
     * @returns True if the input can move, false otherwise.
     */
    static canInputMove = (inputKey: DynamicLayerInput['key'], direction: 'up' | 'down', dynamicLayers?: DynamicLayerInput[]): boolean => {
        if (dynamicLayers === undefined) {
            const frameType = getTemplateData<View['frameType']>('view.frameType');
            dynamicLayers = getTemplateData<Template['dynamicLayers']['main']>(`dynamicLayers.${frameType}`);
        }

        const checkMove = (dynamicLayers: DynamicLayerInput[]) => {
            for (const dynamicLayer of dynamicLayers) {
                if (dynamicLayer.key === inputKey) {
                    const currentInputIndex = dynamicLayers.indexOf(dynamicLayer);
                    if (direction === 'up') {
                        return currentInputIndex > 0;
                    } else {
                        return currentInputIndex < dynamicLayers.length - 1;
                    }
                }

                if ('children' in dynamicLayer && dynamicLayer.children.length > 0) {
                    const canMove = checkMove(dynamicLayer.children);
                    if (canMove) return canMove;
                }
            }

            return false;
        };

        return checkMove(dynamicLayers);
    };

    /**
     *
     * @param inputKey - The key of the input to move.
     * @param direction - The direction to move the input.
     * @param dynamicLayers - The dynamic layers to move the input in.
     * @param frameType - The frame type to move the input in.
     */
    static moveInput = (inputKey: DynamicLayerInput['key'], direction: 'up' | 'down', dynamicLayers?: DynamicLayerInput[]): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        if (dynamicLayers === undefined) {
            dynamicLayers = getTemplateData<Template['dynamicLayers']['main']>(`dynamicLayers.${frameType}`);
        }

        /**
         * Move the input.
         * @param inputs - The inputs to move the input in.
         * @returns The inputs with the moved input.
         */
        const moveInput = (inputs: DynamicLayerInput[]): void => {
            for (const input of inputs) {
                if (input.key === inputKey) {
                    const inputIndex = inputs.indexOf(input);
                    const newIndex = direction === 'up' ? inputIndex - 1 : inputIndex + 1;
                    if (newIndex >= 0 && newIndex < inputs.length) {
                        const [movedInput] = inputs.splice(inputIndex, 1);
                        inputs.splice(newIndex, 0, movedInput);
                    }

                    break;
                }

                if ('children' in input && input.children.length > 0) {
                    moveInput(input.children);
                }
            }
        };

        moveInput(dynamicLayers);
        TemplateDesignerStore.save([`dynamicLayers.${frameType}`, dynamicLayers]);
    };

    /**
     * Get the field label for the given layer title and attribute.
     * @param layerTitle - The title of the layer.
     * @param attribute - The attribute of the input.
     * @returns The field label for the given layer title and attribute.
     */
    static generateFieldLabel = (layerTitle: Layer['title'], attribute: DynamicLayerInput['attribute']): string => {
        let translation = '';
        switch (attribute) {
            case 'visibility':
                translation = `${Translation.get('general.labels.show', 'template-designer')} ${layerTitle}`.toLowerCase();
                return translation.charAt(0).toUpperCase() + translation.slice(1);
            case 'image':
            case 'text':
                return layerTitle;
            default:
                translation = `${layerTitle} ${Translation.get(`dynamicLayers.labels.${attribute}`, 'template-designer')}`.toLowerCase();
                return translation.charAt(0).toUpperCase() + translation.slice(1);
        }
    };

    /**
     * Get the icon for a dynamic layer input.
     * @param type - The type of the dynamic layer input.
     * @param attribute - The attribute of the dynamic layer input.
     * @returns The icon for the dynamic layer input.
     */
    static getDynamicLayerIcon = (attribute: DynamicLayerInput['attribute']): string => {
        switch (attribute) {
            case 'group':
                return 'folder';
            case 'text':
            case 'alt':
                return 'title';
            case 'fontSize':
                return 'format_size';
            case 'lineHeight':
                return 'format_line_spacing';
            case 'fontFamily':
                return 'font_download';
            case 'visibility':
                return 'visibility';
            case 'borderStyle':
                return 'power_input';
            case 'borderColor':
                return 'border_color';
            case 'borderWidth':
                return 'line_weight';
            case 'rotation':
                return 'rotate_right';
            case 'opacity':
                return 'opacity';
            case 'scale':
                return 'photo_size_select_small';
            case 'borderRadius':
                return 'rounded_corner';
            case 'backgroundColor':
                return 'format_color_fill';
            case 'backgroundImage':
            case 'objectPosition':
            case 'image':
                return 'image';
            case 'predefinedImages':
                return 'burst_mode';
            case 'horizontalAlign':
                return 'align_horizontal_center';
            case 'verticalAlign':
                return 'align_vertical_center';
            case 'textAlign':
                return 'format_align_left';
            case 'textDecoration':
                return 'format_underlined';
            case 'textDecorationStyle':
                return 'format_color_text';
            case 'color':
            case 'shadowColor':
            case 'textBorderColor':
                return 'palette';
            case 'video':
                return 'movie';
            case 'audio':
                return 'music_note';
            case 'bannerLoop':
                return 'loop';
            case 'divider':
                return 'horizontal_rule';
            case 'alert':
                return 'error';
            case 'dropdown':
                return 'arrow_drop_down_circle';
            case 'parseValue':
                return 'data_object';
            case 'feedSelectorInput':
                return 'manage_search';
            case 'muteAudio':
                return 'volume_off';
            case 'fadeAudio':
                return 'volume_up';
            case 'flexDirection':
                return 'compare_arrows';
            case 'justifyContent':
                return 'align_vertical_center';
            case 'alignItems':
                return 'align_horizontal_center';
            case 'rowGap':
            case 'columnGap':
                return 'format_line_spacing';
            case 'file':
                return 'draft';
            case 'frameDuration':
            case 'fillToComposition':
            case 'fitToComposition':
                return 'settings';
            case 'frameAlignment':
                return 'grid_on';
            case 'frameFitting':
                return 'photo_size_select_small';
            case 'textJustification':
                return 'format_align_left';
            case 'showTextShadow':
                return 'highlight';
            case 'positionX':
                return 'horizontal_split';
            case 'positionY':
                return 'vertical_split';
            case 'lottie':
                return 'animation';
            case 'jobOptions':
            case 'customInput':
                return 'settings';
            default:
                // eslint-disable-next-line no-console
                console.log('attribute not recognized', attribute);
                return 'settings';
        }
    };

    /**
     * Get the general layer value of the attribute
     * @param attribute - Attribute e.g. 'color' or 'backgroundColor'
     * @param settings - Input settings
     * @param selectedLayer - The selected layer
     * @returns The general layer value of the attribute
     */
    static getGeneralTemplateValue = (attribute: Attributes, settings: DynamicLayerInput['settings'], selectedLayer: Layer): unknown => {
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const templateValue = InterfaceSetupHelpers.getTemplateValue(
            layerProperties,
            frameTypes,
            {
                attribute,
                settings,
                layerKey: selectedLayer.key
            } as DynamicLayerInput,
            selectedLayer,
            frameType
        );

        return templateValue.general;
    };

    /**
     * Get the default color values for an input / Combine brandguide colors and template value
     * @param attribute Attribute e.g. 'color' or 'backgroundColor'
     * @param settings Input settings
     * @param selectedLayer The selected layer
     * @returns The default color values
     */
    static getDefaultColorValues = (attribute: Attributes, settings: DynamicLayerInput['settings'], selectedLayer: Layer): string[] => {
        const templateValue = this.getGeneralTemplateValue(attribute, settings, selectedLayer);
        const brandGuideColors = ColorHelpers.getBrandGuideColorsString(false);
        if (!templateValue) return brandGuideColors;
        return [templateValue as string, ...brandGuideColors];
    };

    /**
     * Checks if Unsplash module is present.
     */
    static hasUnsplash = (): boolean => {
        const modules = Setup.get('modules') ?? [];
        return modules.includes(UNSPLASH_MODULE);
    };

    /**
     * Checks if AI module is present.
     */
    static hasAI = (): boolean => {
        const modules = Setup.get('modules') ?? [];
        return modules.includes(AI_MODULE);
    };

    /**
     * Checks if Aprimo is needed.
     * Used only for Philips.
     */
    static hasAprimo = (): boolean => {
        const accountId = User.get('accountId');
        return APRIMO_ACCOUNT_IDS.includes(accountId);
    };

    /**
     * Checks if compressor should be enabled.
     * @returns True if compressor should be enabled.
     */
    static isCompresserEnabled = (): boolean => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');
        return templateType === 'displayAdDesigned';
    };

    /**
     * Puts together a string of the layer name and the attribute name to quickly show what the input is about.
     * @param input The current input
     * @param layer The current layer
     * @returns String of the layer name > attribute name
     */
    static getFieldPath = (input: DynamicLayerInput, layer: Layer): string => {
        if (layer) {
            return `${'title' in layer && layer.title} > ${StringHelpers.camelCaseToReadable(input.attribute)}`;
        }

        return startCase(input.type);
    };

    /**
     * Updates the input with the general template value. So that value is always present as an option.
     * @param selectedLayer - The layer to update the settings for
     * @param attribute - The attribute of the input
     * @param settings - The settings of the input
     * @param options - The options that are available for the input
     * @returns The updated input settings
     */
    static updateSettingsButtonGroup = (
        selectedLayer: Layer,
        attribute: Attributes,
        settings: AllSettings,
        options: ButtonGroupSettings['options']
    ): AllSettings => {
        const input = cloneDeep(settings) as AllSettings;
        if (!('options' in input)) return input;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, selectedLayer);
        if (!input.options) input.options = [];
        const generalTemplateOption = options.find((option) => option.key === generalTemplateValue);
        const hasValue = input.options.some((option) => option.key === generalTemplateValue);
        if (generalTemplateOption && !hasValue) {
            input.options[0] = generalTemplateOption;
        }
        return input;
    };

    /**
     * Updates the input with the general template value. So that value is always present as an option.
     * @param selectedLayer - The layer to update the settings for.
     * @param attribute - The attribute of the input.
     * @param settings - The settings of the input.
     * @returns The updated input settings.
     */
    static updatSettingsMultiSelectNumber = (selectedLayer: Layer, attribute: Attributes, settings: AllSettings): AllSettings => {
        if (settings.type !== 'select' && settings.type !== 'radioList') return settings;

        const input = cloneDeep(settings) as AllSettings;
        if (!('options' in input)) return settings;
        if (!input.options) input.options = [];

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, selectedLayer);
        input.options = input.options.filter((option) => Number(option.key) !== Number(generalTemplateValue));
        input.options.unshift({ key: Number(generalTemplateValue).toString(), value: Number(generalTemplateValue).toString() });
        return input;
    };

    /**
     * Updates the color input with the general template value. So that value is always present as an option.
     * @param selectedLayer - The layer to update the settings for.
     * @param attribute - The attribute of the input.
     * @param settings - The settings of the input.
     * @returns The updated input settings.
     */
    static updateSettingsColor = (selectedLayer: Layer, attribute: Attributes, settings: AllSettings): AllSettings => {
        const input = cloneDeep(settings) as ColorSettings;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, selectedLayer);
        if (!input.colors) input.colors = [];
        if (generalTemplateValue) {
            input.colors[0] = generalTemplateValue as string;
        }
        input.colors = uniq(input.colors);

        return input;
    };

    /**
     * @returns True if the conditions tab should be visible
     */
    static showConditions = (inputType?: BaseInput['attribute']): boolean => {
        if (inputType === 'customInput') return false;

        const userType = User.getUserType().title;
        if (userType === 'Superadmin') return true; // Superadmins can always see the conditions tab.

        const featureAvailable = FeatureHelpers.hasFeature(Features.DYNAMMIC_LAYERS_CONDITIONS);

        // Special case when the feature is available for specific accounts and user type is Administrator.
        const accountId = User.get('accountId');
        if (DYNAMIC_LAYER_CONDITIONS_ACCOUNT_IDS.includes(accountId)) {
            return featureAvailable && userType === 'Administrator';
        }

        return featureAvailable; // Otherwise users can see the conditions tab if the feature is available.
    };

    /**
     * Calculate the total conditions for the input.
     * @param input - The input to calculate the total conditions for.
     * @param dynamicLayers - The dynamic layers to calculate the total conditions for.
     * @param includeShowCondition - If the show condition should be included in the total conditions.
     * @returns The total conditions for the input.
     */
    static calculateTotalConditions = (input: DynamicLayerInput, dynamicLayers?: Template['dynamicLayers'], includeShowCondition = true): number => {
        if (typeof input.condition === 'string') return input.condition.length ? 1 : 0;

        const activeConditions = input.condition && input.condition.filter((conditionBlock) => conditionBlock.length).length;
        if (activeConditions) return activeConditions;

        if (!includeShowCondition) return 0;

        if (dynamicLayers === undefined) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers', { clone: false });
        const parents = InterfaceSetupHelpers.findInputParents(input.key, dynamicLayers);
        if (parents.length) return 1;
        return 0;
    };

    /**
     * Check if the input can be removed.
     * @param input - The input to check if it can be removed.
     * @returns True if the input can be removed, false otherwise.
     */
    static canRemoveInput = (input: DynamicLayerInput): boolean => {
        return input.attribute !== 'jobOptions';
    };
}

export { DynamicLayerHelpers };
