import get from 'lodash/get';
import { BrandGuideColor, Color, ColorOptions } from 'types/color.type';
import Translation from 'components/data/Translation';
import { ColorHelpers as GlobalColorHelpers } from 'helpers/colors.helpers';
import LayerProperties from '../types/layerProperties.type';
import { FrameTypeProperties } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import BrandGuide from '../types/brandGuide.type';
import { COLOR_ANIMATION_MODELS, COLOR_LAYER_MODELS } from '../constants';
import FormatProperties from '../types/formatProperties.type';

class ColorHelpers {
    /**
     * Converts a color from the color picker to a brandguide color object.
     * @param color - Color from the color picker
     * @returns Brandguide color or false
     */
    private static createBrandguideColor(color: Color): BrandGuideColor | false {
        return {
            brand: 'template',
            brandLabel: Translation.get('sidebarRight.inputs.templateColors', 'template-designer'),
            color
        };
    }

    /**
     * Function to search for colors in a layer or in a format properties object.
     * @param layer - The layer to search for colors in
     * @param newColorsUsedInTemplate - The new colors used in the template
     * @param model - The model of the layer
     */
    static searchForColor = (
        layer: {
            properties: FormatProperties | LayerProperties;
        },
        newColorsUsedInTemplate: BrandGuideColor[]
    ): void => {
        COLOR_LAYER_MODELS.forEach((colorModel) => {
            const color = get(layer as LayerProperties, colorModel);

            //If color exists for current prop model
            if (color) {
                //Make new object like in brand guide
                const newColorObject = this.createBrandguideColor(color);

                if (newColorObject) {
                    newColorsUsedInTemplate.push(newColorObject);
                }
            }
        });

        COLOR_ANIMATION_MODELS.forEach((colorModel) => {
            const colorKeyframes = get(layer as LayerProperties, colorModel);
            if (colorKeyframes) {
                colorKeyframes.forEach((keyframe) => {
                    const color = keyframe.value.color || keyframe.value;
                    //If color exists for current prop model
                    if (color) {
                        //Make new object like in brand guide
                        const newColorObject = this.createBrandguideColor(color);

                        if (newColorObject) {
                            newColorsUsedInTemplate.push(newColorObject);
                        }
                    }
                });
            }
        });
    };

    /**
     * Gets colors used in template and filters out duplicates and brandguide colors.
     * @returns The colors used in the template.
     */
    static filterColorsUsedInTemplate = (colorsUsedInTemplate: BrandGuideColor[]): BrandGuideColor[] => {
        if (!colorsUsedInTemplate || !Object.keys(colorsUsedInTemplate)?.length) return [];

        const brandGuideColors = getTemplateData<BrandGuide['colors']>('brandGuide.colors');

        const filteredColorsUsed: BrandGuideColor[] = [];
        Object.values(colorsUsedInTemplate).forEach((colorUsed) => {
            //Check if already in allColors
            const alreadyExists = [...filteredColorsUsed, ...brandGuideColors].some((existingColor) => {
                return GlobalColorHelpers.getCssColor(existingColor.color) === GlobalColorHelpers.getCssColor(colorUsed.color);
            });
            if (!alreadyExists) {
                //Push to all colors
                filteredColorsUsed.push(colorUsed);
            }
        });

        return filteredColorsUsed;
    };

    /**
     * Get all the colors used in the template and filter duplicates. And return the colors used in the template + the brandguide colors.
     */
    static getPresetColors = (): BrandGuideColor[] => {
        const templateData = getTemplateData<FrameTypeProperties>('layerProperties');
        const newColorsUsedInTemplate: BrandGuideColor[] = [];
        Object.values(templateData).forEach((format) => {
            Object.values(format).forEach((frame) => {
                this.searchForColor(frame, newColorsUsedInTemplate);
                Object.entries(frame).forEach(([layerKey, layer]) => {
                    if (layerKey !== 'properties') {
                        this.searchForColor(
                            layer as {
                                properties: LayerProperties;
                            },
                            newColorsUsedInTemplate
                        );
                    }
                });
            });
        });

        const filteredColorsUsedInTemplate = this.filterColorsUsedInTemplate(newColorsUsedInTemplate);
        const brandGuideColors = getTemplateData<BrandGuide['colors']>('brandGuide.colors');

        return [...filteredColorsUsedInTemplate, ...brandGuideColors];
    };

    /**
     * Gets the brandguide colors and returns them in an array in string form.
     * @param useGradient - If the gradient colors should be included.
     * @returns The brand guide colors in an array in string form.
     */
    static getBrandGuideColorsString = (useGradient = false): string[] => {
        const brandGuideColors = getTemplateData<BrandGuide['colors']>('brandGuide.colors');
        const colorArray = brandGuideColors.map((color) => color.color);

        if (!useGradient) {
            return colorArray.filter((color) => color.type === ColorOptions.Solid).map((color) => GlobalColorHelpers.getCssColor(color));
        }

        return colorArray.map((color) => GlobalColorHelpers.getCssColor(color));
    };

    /**
     * Gets the brandguide colors and returns them in an array in an object form.
     * @param useGradient - If the gradient colors should be included.
     * @returns The brand guide colors in an array in object form.
     */
    static getBrandGuideColors = (useGradient = false): BrandGuideColor[] => {
        const brandGuideColors = getTemplateData<BrandGuide['colors']>('brandGuide.colors');

        if (!useGradient) {
            return brandGuideColors.filter((color) => color.color.type === ColorOptions.Solid);
        }

        return brandGuideColors;
    };
}

export { ColorHelpers };
